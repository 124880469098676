#dashboard {
    display: flex;

    .content {
        width: 100%;
        max-height: calc(100vh - 110px);

        .container {
            height: 100%;
            overflow-y: auto;
            padding: 0 40px;

            .stats {
                display: grid;
                grid-template-columns: auto auto auto;

                .stat {
                    box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.25);
                    border-radius: 20px;
                    width: 460px;
                    height: 260px;
                    margin: 90px 0 0 0;
                    padding: 30px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    >div:first-child {
                        height: 100%;

                        p {
                            font-weight: 500;
                            color: #000;
                            font-size: 20px;
                            margin: 0;
                            white-space: nowrap;
                        }

                        h2 {
                            font-weight: 500;
                            font-size: 32px;
                            margin: 6px 0 0 0;
                        }
                    }

                    img {
                        width: 240px;
                    }
                }
            }

            >div:not(.stats) {
                margin-right: 50px;
            }
        }

        .top {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-top: 50px;
            width: 100%;

            >div {
                width: 100%;

                &.stat {
                    border: 0.5px solid rgba(0, 0, 0, 0.17);
                    border-radius: 10px;
                    padding: 20px 25px 10px 25px;
                    margin: 0 21px;

                    &:first-child {
                        margin-left: 0;
                    }

                    &:last-child {
                        margin-right: 0;
                    }

                    >div {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        p {
                            margin: 0;

                            &.title {
                                font-weight: 500;
                                font-size: 20px;
                                margin-bottom: 25px;
                            }

                            &.value {
                                font-weight: 500;
                                font-size: 32px;

                                &.positive {
                                    color: #42FF00;
                                }

                                &.negative {
                                    color: #ff0000;
                                }
                            }
                        }

                        .percent {
                            border-radius: 5px;
                            font-weight: 500;
                            font-size: 14px;
                            padding: 5px 8px;

                            &.positive {
                                color: #42FF00;
                                background: rgba(66, 255, 0, 0.19);
                                box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.15);
                            }

                            &.negative {
                                color: #ff0000;
                                background: rgba(255, 0, 0, 0.19);
                                box-shadow: 0px 0px 9px 1px rgba(121, 2, 2, 0.15);
                            }
                        }
                    }
                }

                &.search {
                    display: flex;
                    justify-content: flex-end;

                    >div {
                        position: relative;
                        border: 0.5px solid rgba(0, 0, 0, 0.17);
                        border-radius: 5px;
                        padding-left: 34px;
                        padding-right: 10px;

                        input {
                            border: none;
                            padding: 16px 15px;
                            outline: none;
                            font-size: 17px;
                            color: rgba(0, 0, 0, 0.73);

                            &::placeholder {
                                color: currentColor;
                            }
                        }

                        img {
                            left: 16px;
                            top: 50%;
                            transform: translateY(-50%);
                            position: absolute;
                        }
                    }
                }
            }
        }

        .table-container {
            margin-top: 30px;
            width: 100%;
            overflow-y: auto;
            max-height: calc(100vh - 350px);
            border: 0.5px solid rgba(0, 0, 0, 0.17);
            border-radius: 10px;

            table {
                width: 100%;
                border-spacing: 0;

                thead {
                    background: #F7F7F7;
                    height: 80px;

                    th {
                        border-radius: 10px 10px 0 0;
                        text-align: start;
                        font-weight: 600;
                        min-width: 200px;

                        &:nth-child(1) {
                            min-width: 40px;
                        }

                        &:nth-child(2).empty-data {
                            min-width: 70px;
                        }

                        &:nth-child(2):not(.empty-data),
                        &.non-empty-data {
                            min-width: 200px;
                        }

                        &:nth-last-child(2) {
                            width: 100%;
                        }

                        &:last-child {
                            padding-right: 30px;
                        }
                    }
                }

                tbody {
                    tr {
                        height: 80px;

                        &:last-child td {
                            border-bottom: none;
                            word-break: break-all;
                            padding-right: 10px;
                        }

                        td {
                            border-bottom: 0.5px solid rgba(0, 0, 0, 0.17);
                            color: rgba(0, 0, 0, 0.72);
                            font-weight: 400;
                            font-size: 16px;
                            padding: 5px 0;
                            min-width: 200px;

                            p {
                                max-width: 400px;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                display: -webkit-box;
                                -webkit-line-clamp: 3;
                                -webkit-box-orient: vertical;
                            }

                            &:first-child {
                                min-width: 40px;
                                text-align: center;
                            }

                            &:last-child {
                                width: 150px;
                                padding-right: 30px;

                                >div {
                                    display: flex;
                                }
                            }

                            &.member-td {
                                .member-image {
                                    height: 50px;
                                    width: 50px;
                                    padding: 1px;
                                    border-radius: 50%;
                                    box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.064);
                                }
                            }
                        }
                    }
                }

                button {
                    background: #22D1EE;
                    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    border-radius: 5px;
                    font-weight: 500;
                    font-size: 14px;
                    color: #FFFFFF;
                    border: none;
                    padding: 8px 25px;
                    height: 35px;
                    margin: 0 5px;
                    float: right;
                    white-space: nowrap;

                    &:hover {
                        background: #1cbfdc;
                    }

                    &.trash {
                        background: #EB0000;
                        width: 35px;
                        padding: 0;

                        &:hover {
                            background: #c50000;
                        }
                    }
                }
            }
        }

        @media only screen and (max-width: 1819px) {
            .container {
                .stats {
                    grid-template-columns: auto auto;

                    .stat {
                        width: calc(100% - 20px);
                    }
                }
            }
        }

        @media only screen and (max-width: 1280px) {
            .container {
                padding: 0 10px;

                .stats {
                    grid-template-columns: auto;
                    padding-bottom: 30px;

                    .stat {
                        margin-top: 40px;
                        width: 100%;
                    }
                }

                > div {
                    margin: 0 !important;

                    .table-container {
                        table {
                            thead th, tbody tr td {
                                min-width: auto;
                                padding-right: 20px;

                                &:first-child {
                                    min-width: 40px;
                                }
                            }
                        }
                    }
                }
            }
        }
        
        @media only screen and (max-width: 1020px) {
            .top {
                flex-direction: column;

                > div.stat {
                    margin: 0;
                    margin-bottom: 10px;
                }

                > div.search {
                    > div { width: 100%; }
                }
            }

            .container > div .table-container {
                overflow: hidden;
                max-height: fit-content;

                table {
                    thead {
                        th {
                            display: none;

                            &:last-child {
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                padding: 0;
                                padding-top: 20px;
                            }
                        }
                    }
                    tbody > tr {
                        height: fit-content;
                        display: flex;
                        flex-direction: column;
                        border-bottom: 0.5px solid rgba(0, 0, 0, 0.17);
                        text-align: center;
    
                        td {
                            border: none;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            padding: 20px 10px;
                            width: 100%;

                            > div {
                                display: flex;
                                flex-direction: column;
                                align-items: center;

                                button {
                                    margin-bottom: 10px;
                                }
                            }
                        }
                    }
                }
            }
        }

        @media only screen and (max-width: 555px) {
            .container {
                .stats {
                    .stat {
                        flex-direction: column-reverse;

                        img {
                            height: 21vw;
                            width: auto;
                        }

                        >div:first-child {
                            display: flex;
                            align-items: center;

                            h2,
                            p {
                                font-size: 20px;
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .modal {
        position: fixed;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        z-index: 1000;
        background: rgba(0, 0, 0, 0.34);
        display: flex;
        align-items: center;
        justify-content: center;

        >.modal-content {
            position: relative;
            width: 85%;
            height: fit-content;
            z-index: 1001;
            background-color: white;
            box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.25);
            border-radius: 18px;
            padding: 50px 70px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .close {
                color: red;
                position: absolute;
                right: 15px;
                top: 15px;
                background-color: transparent;
                border: none;
                font-size: 20px;
            }

            h3 {
                margin: 0;
                font-weight: 500;
                font-size: 20px;
            }

            .values {
                width: 100%;
                margin-top: 60px;
                display: flex;
                justify-content: space-around;
                flex-wrap: wrap;

                &.center {
                    >div {
                        width: fit-content;

                        >div {
                            width: fit-content;
                        }
                    }
                }

                >div {
                    width: 20%;
                    margin-bottom: 40px;

                    p {
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 22px;
                        color: #000000;
                        margin: 0 0 20px 0;

                        span {
                            font-size: 14px;
                            color: red;
                            margin-left: 5px;
                        }
                    }

                    small {
                        display: block;
                        margin-top: -20px;
                        margin-bottom: 5px;
                        font-size: 11px;
                        width: 70%;
                        color: gray;
                    }

                    >div {
                        width: 130px;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        input,
                        textarea {
                            padding-right: 5px;
                            width: 100%;
                            border: none;
                            outline: none;
                            color: rgba(0, 0, 0, 0.7);
                            font-size: 14px;

                            &::placeholder {
                                color: rgba(0, 0, 0, 0.3);
                            }
                        }

                        textarea {
                            min-width: 170px;
                        }

                        button {
                            justify-content: space-between;
                            font-family: "Nunito";
                            text-transform: none;
                            width: 100%;
                            resize: none;
                            margin-top: -7px;
                            padding: 5px 0;
                            background-color: white !important;
                            font-weight: 400;
                            font-size: 14px;
                            color: #000;
                            outline: none;
                        }

                        .picture-button {
                            background: #22D1EE;
                            box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.25);
                            border-radius: 5px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border: none;
                            padding: 15px;
                            margin-top: -10px;
                            cursor: pointer;

                            &:hover {
                                background: #1cbfdc;
                            }
                        }
                    }
                }
            }

            .update,
            .close-button {
                margin: 0 10px;
                margin-top: 40px;
                background: #22D1EE;
                box-shadow: 0px 0px 9px 1px rgba(0, 0, 0, 0.25);
                border-radius: 8px;
                border: none;
                font-weight: 600;
                font-size: 18px;
                color: #FFFFFF;
                padding: 9px 84px;

                &:hover {
                    background: #1cbfdc;
                }
            }

            .close-button {
                background: #e71409;

                &:hover {
                    background: #ac0c03;
                }
            }

            .modal-2-values {
                height: 100%;
                width: 100%;
                overflow-y: auto;

                >div {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                    padding-left: 20px;

                    p {
                        font-weight: bold;
                        margin: 10px 5px 10px 0;
                    }

                    span {
                        white-space: nowrap;
                    }
                }

                h3 {
                    margin-top: 20px;
                }
            }
        }

        @media only screen and (max-width: 1250px) {
            padding: 20px 0;
            overflow: hidden;

            >.modal-content {
                overflow-x: hidden;
                max-height: 100%;
                width: 95%;
                padding: 30px 10px;

                .values {
                    overflow-x: hidden;
                    overflow-y: auto;

                    > div {
                        width: fit-content;
                        padding: 10px;
                    }
                }

                > div:last-child {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: center;
                    align-items: center;

                    button {
                        margin: 10px;
                    }
                }
            }
        }

        @media only screen and (max-width: 500px) {
            >.modal-content {
                .values {
                    > div {
                        width: 100% !important;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                    }
                }
            }
        }
    }
}