@import 'fonts.scss';

* {
    box-sizing: border-box;
}

html {
    scroll-behavior: smooth;
}

body {
    margin: 0;
    padding: 0;
    background-color: #fff;
    font-family: "Nunito";
    overflow-x: hidden;
}

main {
    max-width: 1920px;
    display: block;
    margin: auto;

    > div {
        max-width: 1920px;
    }
}

input, button, textarea {
    font-family: "Nunito";
}

button {
    cursor: pointer;
    transition: 200ms;
}

a {
    text-decoration: none;
    color: #22D1EE;
    font-weight: 400;
    cursor: pointer;
}

::-webkit-scrollbar {
    width: 7px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #88888885;
    border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
    background: #55555571;
}