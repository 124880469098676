#dashboard-header {
    height: 110px;
    width: 100%;
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.116);
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 90px 10px 40px;

    h1 {
        margin: 0;
        font-size: 28px;
        font-weight: 700;
    }

    > div {
        display: flex;
        align-items: center;

        .pfp {
            height: 60px;
            width: 60px;
            margin-left: 30px;
        }

        .bell {
            margin-left: 20px;
            height: 20px;
            cursor: pointer;
        }

        .logout {
            height: 20px;
            cursor: pointer;
        }
    }

    @media only screen and (max-width: 800px) {
        height: 80px;
        padding: 0 10px 0 70px;
        align-items: center;
    }

    @media only screen and (max-width: 450px) {
        > div .pfp {
            display: none;
        }
    }
}