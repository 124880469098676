#dashboard-nav {
    background-color: rgb(226, 243, 245);
    width: 370px;
    min-height: 100vh;
    height: 100%;
    padding: 80px 10px 10px 80px;

    .logo {
        margin: 0;
        font-weight: 700;
        font-size: 28px;
    }

    .list {
        margin-left: -10px;
        margin-top: 60px;
        display: flex;
        flex-direction: column;

        a {
            font-weight: 600;
            font-size: 22px;
            opacity: 0.61;
            padding: 12px 25px 12px 10px;
            color: #000;
            border-radius: 8px;
            display: flex;
            align-items: center;

            &:hover {
                background-color: rgba(0,0,0,0.05);
            }

            img {
                margin-right: 10px;
            }

            &.active {
                background-color: #22D1EE;
                color: white;
                opacity: 1;

                //Make image white
                img {
                    filter: brightness(0) invert(1);
                }
            }
        }
    }

    &.force-toggle {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 70%;
        display: block !important;
        padding-left: 20px;
        background-color: rgb(226, 243, 245);
        z-index: 100;
    }
    
    @media only screen and (max-width: 800px) {
        display: none;
    }
}

#sidenav-hidden {
    display: none;
    align-items: center;
    height: 80px;

    button {
        position: absolute;
        border: none;
        color: rgb(0, 0, 0);
        background-color: transparent;
        font-size: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 0 50% 50% 0;
        height: 100px;
        width: 30px;
        left: 10px;

        img {
            height: 30px;
        }
    }

    @media only screen and (max-width: 800px) {
        display: flex;
    }
}

.nav-bg {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #0000004d;
    z-index: 99;
}