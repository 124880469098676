/*
 * A lot of elements are same in login and register page
 * So common element's CSS code defined in login page
 * External customization done in signup.css
 */

#login, #signup {
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    overflow-y: auto;
    overflow-x: hidden;

    > div {
        height: 100%;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        &.form-container {
            width: 40%;
            flex-direction: column;

            h1 {
                margin: -60px 0 60px 0;
                font-weight: 600;
                font-size: 28px;
                text-align: center;
            }

            form {
                width: 350px;
                display: flex;
                flex-direction: column;

                h2 {
                    font-weight: 600;
                    font-size: 24px;
                    margin: 0 0 5px 0;
                }

                input:not([type="checkbox"]), textarea, button {
                    font-family: "Nunito";
                    text-transform: none;
                    width: 100%;
                    resize: none;
                    margin-top: 25px;
                    font-weight: 400;
                    font-size: 14px;
                    color: #000;
                    padding: 17px 28px;
                    border: 0.5px solid rgba(0, 0, 0, 0.52);
                    border-radius: 8px;
                    outline: none;

                    &::placeholder {
                        color: rgba(0, 0, 0, 0.6);
                    }
                }

                textarea {
                    height: 140px;
                }

                > div {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 15px;
                    width: 100%;

                    > div {
                        display: flex;
                        align-items: center;
                        width: 100%;

                        &:first-child {
                            color: rgba(0, 0, 0, 0.61);
                            font-size: 13px;

                            input {
                                margin-right: 7px;
                                border-radius: 0;
                            }
                        }
                        &:last-child {
                            font-size: 14px;
                        }
                    }
                }

                .login-button, .register-button, .member-login-button {
                    margin-top: 43px;
                    background: #22D1EE;
                    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
                    border-radius: 8px;
                    border: none;
                    font-weight: 600;
                    font-size: 16px;
                    color: #FFFFFF;
                    padding: 15px 0;
                    width: 350px;

                    &:hover {
                        background: #1ac5e3;
                    }
                }
            }

            .register-button, .member-login-button {
                color: rgba(0, 0, 0, 0.72);
                font-size: 15px;

                a {
                    font-weight: 500;
                }
            }

            .member-login-button {
                margin-bottom: -10px;
            }
        }

        &.image-container {
            position: relative;
            width: 60%;
            display: flex;
            padding-left: 200px;

            .bg {
                width: 100%;
                position: fixed;
                z-index: -2;
                margin-left: -200px;
            }

            > div {
                .blob {
                    position: absolute;
                    left: 200px;
                    right: 0;
                    margin: auto;
                    top: 50%;
                    transform: translateY(-50%);
                    z-index: -1;
                }
            }
        }
    }

    @media only screen and (max-width: 1800px) {
        > div {
            &.form-container {
                width: 50%;

                /* form {
                    input:not([type="checkbox"]), textarea, button {
                        padding: 10px 20px;
                        margin-top: 10px;
                    }
                } */
            }
            &.image-container {
                width: 50%;
                padding-left: 50px;

                .bg {
                    height: max(100vh, 50vw);
                    width: auto;
                }
        
                .vector {
                    height: min(80vh, 40vw);
                }
            }
        }
    }

    @media only screen and (max-width: 1180px) {
        height: fit-content;
        min-height: 100vh;
        align-items: center;
        
        > div {
            &.image-container {
                display: none;
            }
            &.form-container {
                width: 100%;
            }
        }
    }

    @media only screen and (max-width: 600px) {
        > div {
            &.form-container {
                form {
                    padding: 10px;
                    width: 100% !important;

                    > div > div {
                        flex-wrap: wrap;
    
                        input, button {
                            margin-left: 0 !important;
                            margin-right: 0 !important;
                        }
                    }

                    button {
                        width: 100% !important;
                    }
                }
            }
        }
    }
}

.error {
    color: red;
}

.success {
    color: rgb(21, 182, 0);
}