.signup-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.2rem;

    background: url("../../../public/images/login-bg.svg") no-repeat;
    background-position: top right;
    background-size: contain;

    max-height: 100vh;
    overflow: hidden;
    // padding-top: 0.2rem;

    & > .signup-title {
        font-size: 2rem;
        width: 98vw;
        text-align: center;
        font-weight: 600;
        color: #000;
        margin: 0;

        border: 1px solid #22d1ee;
        background: rgba(34, 209, 238, 0.1);
        border-radius: 5px;
        padding: 15px;
        transform: translateY(-2%);
    }
}

#signup {
    > div {
        // align-items: unset;
        justify-content: unset;
    }

    > div.form-container {
        overflow-y: scroll;
        // padding: 20px 0;

        // max-height: 90vh;
        align-self: center;

        // custom scrollbar

        &::-webkit-scrollbar {
            width: 3px;
        }

        &::-webkit-scrollbar-thumb {
            background: rgba(34, 209, 238, 0.2);
            border-radius: 10px;
        }

        &::-webkit-scrollbar-track {
            background: #fcfcfc;
        }

        h1 {
            margin: 50px 0 10px 0;
        }

        form {
            width: 550px;

            .section {
                display: flex;
                flex-direction: column;

                h2 {
                    width: 100%;
                    text-align: flex-start;
                    margin: 2px 0 -10px 0;
                }

                > div {
                    input,
                    button {
                        &:first-child {
                            margin-right: 17px;
                        }
                        &:last-child {
                            margin-left: 17px;
                        }
                    }

                    //Dropdowns
                    button {
                        justify-content: space-between;
                        color: rgba(0, 0, 0, 0.6);
                        padding-right: 20px;
                    }

                    &.picture-container {
                        width: 100%;
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;

                        .picture-button {
                            width: 100%;
                            flex: 1;
                            background: #22d1ee;
                            border-radius: 5px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            border: none;
                            padding: 15px;
                            // margin: 0 auto;
                            margin-top: -10px;
                            cursor: pointer;
                            transition: all 0.2s;

                            &:hover {
                                background: #1cbfdc;
                                box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
                            }
                        }
                    }
                }
            }

            .register-button {
                margin-top: 20px;
                box-shadow: unset;
                transition: all 0.2s;

                &:hover {
                    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);
                }
            }
        }
    }

    .image-container {
        background: url("../../../public/images/signup-vector.svg") no-repeat;
        background-position: center center;
        background-size: 60vh;
    }
}

.signup-dropdown .MuiPaper-root {
    width: 260px;
    box-shadow: 0px 0px 12px 1px rgba(0, 0, 0, 0.17);
    margin-top: 10px;
    border-radius: 10px;
}

@media only screen and (max-width: 1180px) {
    .signup-container {
        max-height: unset;
        background-size: cover;
        background-position: right -35vw center;

        & > .signup-title {
            font-size: 1.6rem;
        }
    }
}

@media only screen and (max-width: 768px) {
    .signup-container {
        max-height: unset;
        background-size: cover;
        background-position: right 200% center;
        background-attachment: fixed;
        gap: 0;

        & > .signup-title {
            font-size: 1.6rem;
        }
    }
}

@media only screen and (max-width: 450px) {
    .signup-container {
        max-height: unset;
        background-size: cover;
        background-position: right 100% center;
        background-attachment: fixed;
        gap: 0;

        & > .signup-title {
            font-size: 1.6rem;
        }
    }
}

input.red-asterisk {
    background-image: linear-gradient(to right, transparent 90%, red 90%, red 95%, transparent 95%);
    background-repeat: no-repeat;
    background-position: right 5px center;
    padding-right: 15px;
}